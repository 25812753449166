<template>
  <div class="legal">
    <div class="container">
      <div class="row">
        <div class="col box-wrapper">
          <div v-html="content"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'legal',
  components: {
  },
  data() {
    return {
      content: ''
    }
  },
  mounted() {
    this.loadText();
  },
  methods: {
    loadText() {
      const contentName = this.$route.params.contentName;
      const lang = 'en';

      axios
        .get(`/legal/${lang}/${contentName}.html`)
        .then((res) => {
          this.content = res.data;
        })
        .catch(() => {
          this.content = '<h1>Error, try to load this page later.</h1>';
        });
    }
  },
  watch: {
    '$route.params.contentName': function () {
      this.loadText();
    }
  }
}
</script>

<style lang="scss">

</style>
